import React from "react";
import { Link, HeadFC, graphql } from "gatsby";
import SEO from "../seo";
import smile from "../images/smile.png";
import Layout from "../components/layout";
import { HeaderSpacer } from "../components/header";

const Success = () => {
  return (
    <Layout>
      <HeaderSpacer />
      <div className="flex items-center justify-center p-4">
        <img
          src={smile}
          title="smiling panzerotto"
          className="w-32 lg:w-64 h-auto animate-wiggle"
        />
        <div className="flex flex-col ml-4">
          <h1 className="text-brown-500 uppercase text-5xl whitespace-pre-line">
            Thanks for your submission!
          </h1>
          <Link
            to="/"
            className="text-red-500 uppercase text-4xl mt-8 w-fit snake"
          >
            Go home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Success;

export const Head: HeadFC = () => <SEO title="Request Sent" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
